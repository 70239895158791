export const handleScroll = (callback) => {
    const onScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
  
      if (callback && typeof callback === 'function') {
        callback(scrollTop);
      }
    };
  
    window.addEventListener('scroll', onScroll);
  
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  };