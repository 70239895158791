import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-ProNotarBlue text-white py-3">
      <div className="mx-auto standardPadding">
        <div className="justify-between items-center">
          <div>
            <p className="text-sm mb-1">&copy; {new Date().getFullYear()} Copyright ProNotar. Alle Rechte vorbehalten.</p>
            <p className="text-sm">Andreas Krauß Hard- und Softwareservice GmbH, Grub 91, 94539 Grafling | Telefon 0800 / 959 72 99</p>
          </div>
          <div>
            <ul className="flex space-x-6">
                <li><a href="https://www.pronotar.de/Docs/Datenschutzbestimmungen_nach_DSGVO_Andreas_Krauss_GmbH.pdf" target="_blank" rel="noopener noreferrer" className="text-sm underline">Datenschutz</a></li>
                <li><Link to="/contact" className="text-sm underline">Kontakt</Link></li>
                <li><Link to="/imprint" className="text-sm underline">Impressum</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
