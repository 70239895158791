import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const Home = lazy(() => import('./components/pages/Home.js'));
const Imprint = lazy(() => import('./components/pages/Imprint.js'));
const NotFound = lazy(() => import('./components/pages/NotFound.js'));
const Pricing = lazy(() => import('./components/pages/Pricing.js'));
const Contact = lazy(() => import('./components/pages/Contact.js'));
const Support = lazy(() => import('./components/pages/Support.js'));
const Jobs = lazy(() => import('./components/pages/Jobs.js'));


const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/support" element={<Support />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;